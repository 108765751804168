#root{
  position: relative;
}

html,body{
  font-family: 'Poppins', sans-serif;
}

.left-10{
  left: 10% !important;
}

.w-50-200{
  width: 180px !important;
}

.cursor-pointer{
  cursor: pointer;
}

.f-size-12{
  font-size: 13px;
  line-height: 18px;
}

.simula_parrafos{
  text-align: justify;
}

.simula_parrafos div{
  margin-bottom: 10px;
}

.social-network-sidebar{
  right: 0;
  top:200px;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.5);
  width: 60px;
  max-width: 70px;
  -webkit-border-top-left-radius: 20px;
  -webkit-border-bottom-left-radius: 20px;
  -moz-border-radius-topleft: 20px;
  -moz-border-radius-bottomleft: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
a.lnk-free,
a.lnk-free:hover,
a.lnk-free:link{
  text-decoration: none !important;
  color: black;
}


.border-blue{
  border:solid 2px #009EFF  !important;

}

.bg-cover-blue{
  background: url("./assets/images/icons/background.png") no-repeat center center ;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.w-ico{
  width: 30px;
}

.content-form{
  border: solid 2px #ccc;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.btn-danger, .btn-danger:hover, .btn-danger:active, .btn-danger:visited {
    background-color: #F40208 !important;
}

.textura1{
  background-image: url("./assets/images/conocenos/textura.png");
}

.text-danger2 {
  color: #F40208 !important;
}


.imageWrapper {
  position: relative;
  width: 100%;
}

.imageWrapper .cornerLink {
    cursor: pointer;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    color: #ffffff;
    background-color: rgba(0, 0, 255, 0.8);
    text-decoration: none;
    text-align: center;
    -webkit-transition: opacity 500ms;
    -moz-transition: opacity 500ms;
    -o-transition: opacity 500ms;
    transition: opacity 500ms;

}
.imageWrapper:hover .cornerLink {
      opacity: 0.8;
}

.content-header{
  top: 10px;
  max-width: 40%;
  color: #fff;
  left:10%;
  top: 20%;
}

.content-header h2{
  font-weight: bold;
  font-size: 60px;
  line-height: 54px;
}

.content-header2{
  top: 10px;
  max-width: 40%;
  color: #fff;
  right:10%;
  top: 20%;
}

.content-header2 h2{
  font-weight: bold;
  font-size: 60px;
  line-height: 54px;
}


.content-header4{
  max-width: 30%;
  color: #fff;
  right:7%;
  top: 20%;
}

.content-header4 h2{
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
}

.content-header3{
  top: 10px;
  max-width: 40%;
  color: #fff;
  right:18%;
  top: 20%;
}

.content-header3 h2{
  font-weight: bold;
  font-size: 50px;
  line-height: 50px;
}


.text-200{
  width: 150px;
}

.mx-height{
  max-height: 400px;
  overflow: hidden;
}

.aliados div{
  font-size: 12px;
}
.social-network-sidebar img{
  width: 50px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logo{
  width: 80px;
  height: 80px;
}

.whatsapp{
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 10px;
}

.h-300{
  height: 300px;
  overflow:hidden;
  font-size: 13px;
}

.footer{
  background-color: #F40208;
}

.footer-2{
  background-color: #333;
}

.footer-3{
  background-color: #000;
}

.social-network{
  width: 30px;
  margin: 5px;
  cursor: pointer;
}

.pqr{
  background-color: #FFC200;
  width: 100px;
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  -webkit-border-bottom-right-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-bottomright: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.btn-conocenos{
  margin-top: 6px;
  border-radius: 100px !important;
  padding:  1px 10px !important;
  height: 30px;
  background-color: #009EFF !important;
}

.text-blue{
  color: #009EFF !important;
}

.bg-absolute{
  width: 100% !important;
  z-index: -10;
}
.bg-blue-400{
  background-color: blue;
}



.balance .text-label{
  left: 30%;
  width: 130px;;
  top: 10%;
  text-align: left;
  color: #fff;
  font-size: 13px;
}

.btn-oficina{
  border-radius: 100px !important;
}

.btn-variant{
  border-radius: 100px !important;
  padding:  4px 20px !important;
  border: none !important;
}

.cardContent .img{
  min-height: 320px;
}

.btn-variant-blue{
  background-color: #009EFF !important;
}

.title-red{
  color: red !important;
}

.title-blue{
  color: #009EFF !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-10{
  font-size: 11px;
  width: 90%;
  margin: 0 auto;
}

.text{
  top: 50px;
  max-width: 70%;
  font-size: 14px;
}

.text-1{
  top: 30px;
  max-width: 50%;
}

.programandoweb-blog  h1,
.programandoweb-blog  h2,
.programandoweb-blog  h3,
.programandoweb-blog  h4{
  font-size: 1.75rem;
}

.programandoweb-blog  h1,
.programandoweb-blog  h1 strong{
  color: #dc3545 !important;
}


@media(max-width:770px){
  .text-10{
    font-size: 11px;
    width: 90%;
    margin: 0 auto;
  }
  .text-1{
    top: 2px;
    max-width: 80%;
    font-size: 12px;
    line-height: 12px;
  }
  .text-1 h2,.content-header h2{
    font-size: 16px;
    margin: 0 !important;
  }

  .content-header {
    max-width: 70%;
    left: 12px;
    top: 0px;
  }

  .content-header p {
    font-size: 15px !important;
    line-height: 15px;
  }

  .content-header h2{
    font-size: 24px !important;
    font-weight: bold;
    line-height: 24px;
  }

  .content-header4{
    top: 20% !important;
    width: 80%;
    max-width: 80% !important;
    color: #fff;
    right:0%;
  }

  .content-header4 h2{
    font-weight: bold;
    line-height: 20px;
    text-align: center;
    padding-right: 40px;
  }

  .content-header3{
    top: 0 !important;
    width: 100%;
    max-width: 100% !important;
    color: #fff;
    left:0%;
  }

  .content-header3 h2{
    font-weight: bold;
    line-height: 20px;
    text-align: right;
    padding-right: 12px;
  }

  .img-mini{
    margin-top: 70px;
  }

  .text{
    max-width: 90%;
    font-size: 12px;
    line-height: 12px;
    padding-left: 100px;
  }
  .h3, h3 {
    font-size: 1.1rem !important;
  }
  .h5, h5 {
    font-size: 1rem !important;
  }

  .content-header2 h2{
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
  }

}
